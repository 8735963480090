import React from 'react';
import { graphql } from 'gatsby';
import BlogLanding from '../components/organisms/BlogLanding/BlogLanding';

const IndexPage = ({ data, pageContext }) => {
  const {
    allWpPost: { edges: posts },
    allWpCategory: { nodes: categories }
  } = data;

  return (
    <BlogLanding
      posts={posts || []}
      categories={categories || []}
      pageContext={pageContext}
      title="Latest posts"
    />
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWpCategory {
      nodes {
        id
        uri
        name
        description
      }
    }
  }
`;
